<template>

    <div>
   <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <base-material-stats-card
          color="info"
          icon="mdi-twitter"
          title="Followers"
          value="+245"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>
         <base-material-card
          color="warning"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Employees Stats
            </div>

            <div class="subtitle-1 font-weight-light">
              New employees on 15th September, 2016
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
            />
          </v-card-text>
        </base-material-card>

     
  <h1>here we are</h1>
    <hr>
        <div class="test">
          <div v-for="chart in ChartItems" :key="chart.id">
             <component :is="chart.type" :color="chart.data.color" :data="chart.data" :height="350"
             :width="350"></component>     
          </div>
        </div>
    </div>
</template>
<script>
import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";
import RadialRadar from "../../charts/Radar/RadialRadar.vue"
import Radar from "../../charts/Radar/Radar.vue"
import DistributedTreemap from "../../charts/Treemap/DistributedTreemap.vue"
import BasicArea from "../../charts/Area/Basic.vue"
import MultiseriesTimeline from "../../charts/Timeline/Multiseries.vue"
import BasicTimeline from "../../charts/Timeline/Basic.vue"
import Pie from "../../charts/Pie/Pie.vue";
import DashedLine from "../../charts/Line/Dashed.vue";
import BasicLine from "../../charts/Line/Basic.vue";
import ZoomableTimeseries from '../../charts/Line/ZoomableTimeseries.vue';
import LineDatatable from '../../charts/Line/LineDatatable.vue';
import ColumnBasic from '../../charts/Bar/ColumnBasic.vue';
import ColumnDatalabel from '../../charts/Bar/ColumnDatalabel.vue';
import StackedColumn from '../../charts/Bar/StackedColumn.vue';
import ColumnWithNegativeValue from '../../charts/Bar/ColumnWithNegativeValue.vue';
import ColumnWithMarkers from '../../charts/Bar/ColumnWithMarkers.vue';
import BasicPolarArea from '../../charts/Polar/BasicPolarArea.vue';
export default {
    components: { Pie,BasicLine, ZoomableTimeseries,DashedLine, LineDatatable, ColumnBasic, ColumnDatalabel, StackedColumn, ColumnWithNegativeValue, ColumnWithMarkers,BasicTimeline,MultiseriesTimeline, BasicPolarArea,BasicArea,DistributedTreemap,Radar,RadialRadar, Dashboard,
    DashLayout,
    DashItem},
data(){
    return{
       headers: [
          {
            sortable: false,
            text: 'ID',
            value: 'id',
          },
          {
            sortable: false,
            text: 'Name',
            value: 'name',
          },
          {
            sortable: false,
            text: 'Salary',
            value: 'salary',
            align: 'right',
          },
          {
            sortable: false,
            text: 'Country',
            value: 'country',
            align: 'right',
          },
          {
            sortable: false,
            text: 'City',
            value: 'city',
            align: 'right',
          },
        ],
        items: [
          {
            id: 1,
            name: 'Dakota Rice',
            country: 'Niger',
            city: 'Oud-Tunrhout',
            salary: '$35,738',
          },
          {
            id: 2,
            name: 'Minerva Hooper',
            country: 'Curaçao',
            city: 'Sinaai-Waas',
            salary: '$23,738',
          },
          {
            id: 3,
            name: 'Sage Rodriguez',
            country: 'Netherlands',
            city: 'Overland Park',
            salary: '$56,142',
          },
          {
            id: 4,
            name: 'Philip Chanley',
            country: 'Korea, South',
            city: 'Gloucester',
            salary: '$38,735',
          },
          {
            id: 5,
            name: 'Doris Greene',
            country: 'Malawi',
            city: 'Feldkirchen in Kārnten',
            salary: '$63,542',
          },
        ],
      dlayouts: [
        {
          breakpoint: "xl",
          numberOfCols: 12,
          items: [
            { id: "1", x: 0, y: 0, width: 1, height: 1 },
            { id: "2", x: 1, y: 0, width: 2, height: 1 },
            { id: "3", x: 2, y: 0, width: 2, height: 1 },
          ]
        }
      ],

         ChartItems:[
             {
            "id": 1,
            "type": "BasicLine",
            "data":{
            "xAxis": ["A","B","C","D","E","F","G"],
            "yAxis":[80,20,40,87,45,65,89]
            }},
            {
            "id": 2,
            "type": "DashedLine",
            "data":{
            "xAxis": ['01 Jan', '02 Jan', '03 Jan', '04 Jan', '05 Jan', '06 Jan', '07 Jan', '08 Jan','09 Jan','10 Jan', '11 Jan', '12 Jan'],
            "yAxis": [{
              name: "Session Duration",
              data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10]
            },
            {
              name: "Page Views",
              data: [35, 41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35]
            },
            {
              name: 'Total Visits',
              data: [87, 57, 74, 99, 75, 38, 62, 47, 82, 56, 45, 47]
            },
             {
              name: 'Total fees',
              data: [34,45,65,78,98,99,78,67,76,56,87,78]
            },
             {
              name: 'Total sold',
              data: [24,85,35,88,38,29,48,17,46,46,97,18]
            }
          ],
         

            }
            },
             {
            "id": 3,
            "type": "LineDatatable",
            "data":{
            "xAxis": ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
            "yAxis": [
            {
              name: "High - 2013",
              data: [28, 29, 33, 36, 32, 32, 33]
            },
            {
              name: "Low - 2013",
              data: [12, 11, 14, 18, 17, 13, 13]
            }
          ]
            }},
             {
            "id": 4,
            "type": "ColumnBasic",
            "data":{
            "xAxis":  ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            "yAxis": [{
            name: 'Net Profit',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
          }, {
            name: 'Revenue',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
          }, {
            name: 'Free Cash Flow',
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
          },{
            name: 'Car Flow',
            data: [95, 51, 36, 86, 55, 88, 72, 43, 81]
          }]
            }},
             {
            "id": 5,
            "type": "ColumnDatalabel",
            "data":{
            "xAxis":["Jan", "Feb", "Mar", "Apr","May","Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
             "yAxis":[2.3, 3.1, 4.0, 6.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
           
            }},
             {
            "id": 6,
            "type": "StackedColumn",
            "data":{
            "xAxis": ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT','01/05/2011 GMT', '01/06/2011 GMT'],
            "yAxis": [{
            name: 'PRODUCT A',
            data: [44, 55, 41, 67, 22, 43]
          }, {
            name: 'PRODUCT B',
            data: [13, 23, 20, 8, 13, 27]
          }, {
            name: 'PRODUCT C',
            data: [11, 17, 15, 15, 21, 14]
          }, {
            name: 'PRODUCT D',
            data: [21, 7, 25, 13, 22, 8]
          }]
            }},
             {
            "id": 7,
            "type": "ColumnWithNegativeValue",
            "data":{
            "xAxis": [
                '2011-01-01', '2011-02-01', '2011-03-01', '2011-04-01', '2011-05-01', '2011-06-01',
                '2011-07-01', '2011-08-01', '2011-09-01', '2011-10-01', '2011-11-01', '2011-12-01',
                '2012-01-01', '2012-02-01', '2012-03-01', '2012-04-01', '2012-05-01', '2012-06-01',
                '2012-07-01', '2012-08-01', '2012-09-01', '2012-10-01', '2012-11-01', '2012-12-01',
                '2013-01-01', '2013-02-01', '2013-03-01', '2013-04-01', '2013-05-01', '2013-06-01',
                '2013-07-01', '2013-08-01', '2013-09-01'
              ],
            "yAxis": [1.45, 5.42, 5.9, -0.42, -12.6, -18.1, -18.2, -14.16, -11.1, -6.09, 0.34, 3.88, 13.07,
              5.8, 2, 7.37, 8.1, 13.57, 15.75, 17.1, 19.8, -27.03, -54.4, -47.2, -43.3, -18.6, -
              48.6, -41.1, -39.6, -37.6, -29.4, -21.4, -2.4
            ]
            }},
            {
                 "id": 8,
            "type": "ColumnWithMarkers",
            "data":[
                                {
                  x: '2011',
                  y: 1292,
                  goals: [
                    {
                      name: 'Expected',
                      value: 1400,
                      strokeHeight: 5,
                      strokeColor: '#775DD0'
                    }
                  ]
                },
                {
                  x: '2012',
                  y: 4432,
                  goals: [
                    {
                      name: 'Expected',
                      value: 5400,
                      strokeHeight: 5,
                      strokeColor: '#775DD0'
                    }
                  ]
                },
                {
                  x: '2013',
                  y: 5423,
                  goals: [
                    {
                      name: 'Expected',
                      value: 5200,
                      strokeHeight: 5,
                      strokeColor: '#775DD0'
                    }
                  ]
                },
                {
                  x: '2014',
                  y: 6653,
                  goals: [
                    {
                      name: 'Expected',
                      value: 6500,
                      strokeHeight: 5,
                      strokeColor: '#775DD0'
                    }
                  ]
                },
                {
                  x: '2015',
                  y: 8133,
                  goals: [
                    {
                      name: 'Expected',
                      value: 6600,
                      strokeHeight: 13,
                      strokeWidth: 0,
                      strokeLineCap: 'round',
                      strokeColor: '#775DD0'
                    }
                  ]
                },
                {
                  x: '2016',
                  y: 7132,
                  goals: [
                    {
                      name: 'Expected',
                      value: 7500,
                      strokeHeight: 5,
                      strokeColor: '#775DD0'
                    }
                  ]
                },
                {
                  x: '2017',
                  y: 7332,
                  goals: [
                    {
                      name: 'Expected',
                      value: 8700,
                      strokeHeight: 5,
                      strokeColor: '#775DD0'
                    }
                  ]
                },
                {
                  x: '2018',
                  y: 6553,
                  goals: [
                    {
                      name: 'Expected',
                      value: 7300,
                      strokeHeight: 2,
                      strokeDashArray: 2,
                      strokeColor: '#775DD0'
                    }
                  ]
                }
         ],
            },
              {
            "id": 9,
            "type": "BasicTimeline",
            "data":[
                {
                  x: 'Code',
                  y: [
                    new Date('2019-03-02').getTime(),
                    new Date('2019-03-04').getTime()
                  ]
                },
                {
                  x: 'Test',
                  y: [
                    new Date('2019-03-04').getTime(),
                    new Date('2019-03-08').getTime()
                  ]
                },
                {
                  x: 'Validation',
                  y: [
                    new Date('2019-03-08').getTime(),
                    new Date('2019-03-12').getTime()
                  ]
                },
                {
                  x: 'Deployment',
                  y: [
                    new Date('2019-03-12').getTime(),
                    new Date('2019-03-18').getTime()
                  ]
                }
              ]},
               {
            "id": 10,
            "type": "MultiseriesTimeline",
            "data":  [
            {
              name: 'Bob',
              data: [
                {
                  x: 'Design',
                  y: [
                    new Date('2019-03-05').getTime(),
                    new Date('2019-03-08').getTime()
                  ]
                },
                {
                  x: 'Code',
                  y: [
                    new Date('2019-03-08').getTime(),
                    new Date('2019-03-11').getTime()
                  ]
                },
                {
                  x: 'Test',
                  y: [
                    new Date('2019-03-11').getTime(),
                    new Date('2019-03-16').getTime()
                  ]
                }
              ]
            },
            {
              name: 'Joe',
              data: [
                {
                  x: 'Design',
                  y: [
                    new Date('2019-03-02').getTime(),
                    new Date('2019-03-05').getTime()
                  ]
                },
                {
                  x: 'Code',
                  y: [
                    new Date('2019-03-06').getTime(),
                    new Date('2019-03-09').getTime()
                  ]
                },
                {
                  x: 'Test',
                  y: [
                    new Date('2019-03-10').getTime(),
                    new Date('2019-03-19').getTime()
                  ]
                }
              ]
            }
          ]},
           {
            "id": 11,
            "type": "BasicPolarArea",
            "data":{
                "yAxis":[14, 23, 21, 17, 15],
            "xAxis":['Rose A', 'Rose B', 'Rose C', 'Rose D', 'Rose E']}
            },
              {
            "id": 12,
            "type": "BasicArea",
            "data":{
            "xAxis": ["A","B","C","D","E","F","G"],
            "yAxis":[80,20,40,87,45,65,89]
            }},
            {
            "id": 13,
            "type": "DistributedTreemap",
            "data": [
                 
                {
                  x: 'New Delhi',
                  y: 218
                },
                {
                  x: 'Kolkata',
                  y: 149
                },
                {
                  x: 'Mumbai',
                  y: 184
                },
                {
                  x: 'Ahmedabad',
                  y: 55
                },
                {
                  x: 'Bangaluru',
                  y: 84
                },
                {
                  x: 'Pune',
                  y: 31
                },
                {
                  x: 'Chennai',
                  y: 70
                },
                {
                  x: 'Jaipur',
                  y: 30
                },
                {
                  x: 'Surat',
                  y: 44
                },
                {
                  x: 'Hyderabad',
                  y: 68
                },
                {
                  x: 'Lucknow',
                  y: 28
                },
                {
                  x: 'Indore',
                  y: 19
                },
                {
                  x: 'Kanpur',
                  y: 29
                }
              ]},
                {
            "id": 14,
            "type": "Radar",
            "data":{
            "xAxis": ["A","B","C","D","E","F","G"],
            "yAxis":[80,20,40,87,45,65,89]
            }},
              {
            "id": 15,
            "type": "RadialRadar",
            "data":{
            "xAxis": ["A","B","C","D","E","F","G"],
            "yAxis":[80,20,40,87,45,65,89]
            }}
        ]
       
    }
}
}
</script>

<style scoped>
.test{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    align-items: center;
}
.content {
  height: 100%;
  width: 100%;
  border: 2px solid #42b983;
  border-radius: 5px;
}
 
</style>